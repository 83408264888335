import React, { useLayoutEffect } from 'react'
import Carousels from '../../components/carousel'
import Layout from '../../components/layout'
import Pager from '../../components/pager'
import Seo from '../../components/seo'
// import pictureFeedNews from '../../images/notices.jpeg'
import { graphql, Link, navigate } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import urlSlug from 'url-slug'
import { useWindowSize } from '../../hooks/useWindowSize'
import { getDateFormatted } from '../../helpers/getDateFormatted'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DIARY_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'

const diariesOu = ({ data, pageContext }) => {
  const {
    strapiOrganizationalUnit: organizationalUnit,
    allStrapiDiary: diaries
  } = data

  const objCarousel = {
    visible: true,
    slide: organizationalUnit.mainCover?.diariesCover
  }

  const [width] = useWindowSize()
  const isHomeUcc = false
  useLayoutEffect(() => {
    if (width < 768) {
      return window.scrollTo({ top: 265, behavior: 'smooth' })
    } else if (width < 1400) {
      return window.scrollTo({ top: 270, behavior: 'smooth' })
    } else if (width < 1600) {
      return window.scrollTo({ top: 304, behavior: 'smooth' })
    } else {
      return window.scrollTo({ top: 370, behavior: 'smooth' })
    }
  }, [width])

  const handleClickBack = (e) => {
    e.preventDefault()
    navigate(`/${urlSlug(organizationalUnit.slug)}/${DIARY_GENERAL_ROUTE}/`, {
      replace: false
    })
  }

  return data ? (
    <Layout page={organizationalUnit} logo={organizationalUnit.logo}>
      <Seo title={`Agenda ${organizationalUnit.name}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0 ? 
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'newsFeed-'} />
          </div>
        : null}
        <div className="container">
          <div className="my-5">
            <div className="d-flex align-items-center justify-content-center row mb-4">
              <div className="col-12 col-md-8 mb-3  mb-auto">
                <h5 className=" text-uppercase title-secondary mb-3 text-justify">
                  Agenda{' '}
                  <span className="font-weight-bold text-main">
                    {organizationalUnit.name}
                  </span>
                </h5>
              </div>
              <form className=" col-12 col-md-4 ">
                <div className="d-flex flex-md-column feed-form">
                  <div className=" d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleClickBack}
                    >
                      Ver todas
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="row ">
              {diaries.nodes.map((diary) => {
                const objDate = getDateFormatted(new Date(diary.date))
                return (
                  <div
                    className={
                      'col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4 mb-3 d-flex align-items-stretch'
                    }
                    key={diary.strapiId}
                  >
                    <div
                      key={`diaresId-${diary.strapiId}`}
                      className={
                        'schedule-card-wrapper mx-2 p-4 h-100 rounded shadow-sm bg-white'
                      }
                      style={{ minHeight: 292 + 'px' }}
                    >
                      <Link
                        to={`/${DIARY_GENERAL_ROUTE}/${diary?.slug}`}
                        className="text-decoration-none"
                      >
                        <div className="text-center text-uppercase d-flex flex-column align-items-center justify-content-center py-2 border-bottom">
                          <h6
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          >
                            {objDate.day}
                          </h6>
                          <h1
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          >
                            {objDate.numberDay}
                          </h1>
                          <h6
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          >
                            {objDate.mounth}
                          </h6>
                        </div>
                        <div className="h-50 text-uppercase d-flex flex-column align-items-stretch justify-content-between pt-2">
                          <ReactMarkdown
                            escapeHtml={false}
                            source={diary.title}
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          />
                          <h6>
                            <FontAwesomeIcon
                              icon={['fas', 'map-marker-alt']}
                              size="lg"
                              className={`mr-1 ${
                                isHomeUcc ? 'ucc-text-blue' : ''
                              }`}
                            />
                            <span
                              className={`font-weight-bold text-capitalize ${
                                isHomeUcc ? 'ucc-text-blue' : ''
                              }`}
                            >
                              {diary.place}
                            </span>
                          </h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  ) : null
}

export default diariesOu
export const newListQueryOU = graphql`
  query(
    $skip: Int
    $limit: Int
    $date: Date
    $orgUnitId: Int
    $category: String
  ) {
    allStrapiDiary(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: ASC }
      filter: {
        date: { gte: $date }
        organizational_unit: { id: { eq: $orgUnitId } }
        diary_category: { name: { eq: $category } }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        date(formatString: "YYYY/MM/DD", locale: "es")
        time
        place
        diary_category {
          name
        }
      }
    }
    strapiOrganizationalUnit(strapiId: { eq: $orgUnitId }) {
      name
      slug
      logo {
        url
        image {
          url
        }
      }
      navbar {
        name
        icon {
          code
          type
        }
        careersList {
          name
          ucc_careers {
            name
            slug
          }
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            name
            slug
          }
        }
        organizational_unit {
          name
          slug
        }
        page {
          name
          slug
        }
        pages {
          name
          icon {
            code
            type
          }
          page {
            name
            slug
          }
        }
      }
      mainCover {
        diariesCover {
          visible
          url
          name
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
